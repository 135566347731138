<template>
  <v-app-bar
    flat
    color="black"
    class="rounded-t-0 rounded-b-xl"
    absolute
    dense
    short
  >
    <div
      class="d-flex flex-row align-center justify-space-between"
      style="width: 100%;"
    >
      <v-btn
        icon
        color="white"
        :disabled="disabled"
        @click="handleClose"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-toolbar-title class="white--text pl-0">{{ detail.properties.object_name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        color="white"
        :disabled="disabled"
        @click="handleInformation"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>

      <!--    <v-btn
            icon
            color="white"
            :disabled="disabled"
            @click="handleVerification"
          >
            <v-icon>mdi-handshake-outline</v-icon>
          </v-btn>-->
      <!--      note кнопка создания (рисования): -->
      <portal
        v-if="!isOpeningObject"
        to="portal-bottom-right-controls"
      >
        <v-btn
          @click="handleObjectPanel"
          class="create-button pa-0"
        >
          <div class="icon-plus"></div>
        </v-btn>
      </portal>

      <BaseDialog
        v-model="infoOpen"
        hide-scroll
        :is-header-spacer="false"
        max-width="100vw"
        is-full-screen
        is-cross-close
        id-card="info-object"
        with-label
        is-swipe-line
        without-sides-padding
        additional-class="dialog-mobile-object"
      >
        <template #content>
          <PassportInfo
            v-model="detail"
            :title="detail.properties.object_name"
          />
        </template>
      </BaseDialog>
      <BaseDialog
        v-model="objectPanelOpen"
        is-full-screen
        hide-scroll
        little-label
        max-width="100vh"
        is-cross-close
        card-class="object-panel-card"
        is-swipe-line
        with-label
        id-card="object-panel"
      >
        <template #content>
          <ObjectPanel
            :objects="detail.properties.passportEntities"
            :is-disable-interaction="isDisableInteraction"
            :currentIdCreatingMapObject="currentIdCreatingMapObject"
            @create="handleObjectPanelGeometry"
            @hideItem="$emit('hideItem', $event)"
            @showFilters="handleFilter"
            @resetFilter="handleResetFilter"
            @blockLayer="handleBlockLayer"
          />
        </template>
      </BaseDialog>
    </div>
  </v-app-bar>
</template>

<script>
import BaseDialog from '@/components/base/mobile/BaseDialog'
import PassportInfo from '@/components/views/mobile/map/detail/headers/passportHeader/PassportInfo.vue'
import ObjectPanel from '@/components/views/mobile/map/detail/headers/passportHeader/ObjectPanel'

export default {
  name: 'PassportHeader',
  components: { PassportInfo, BaseDialog, ObjectPanel },
  mixins: [],
  props: {
    detail: Object,
    disabled: Boolean,
    isDisableInteraction: Boolean,
    currentIdCreatingMapObject: Number,
    isOpeningObject: Boolean,
    isInfoPassportOpen: Boolean,
    isObjectPanel: Boolean
  },
  watch: {
    isInfoPassportOpen: {
      handler () {
        this.infoOpen = this.isInfoPassportOpen
      }
    },
    isObjectPanel: {
      handler () {
        this.objectPanelOpen = this.isObjectPanel
      }
    },
  },
  data () {
    return {
      infoOpen: false,
      objectPanelOpen: false
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    /*  handleVerification () { note: для дальнейшей верификации
        this.$emit('verify')
      },*/
    handleInformation () {
      this.infoOpen = !this.infoOpen
      this.$emit('handleInfoPassport', this.infoOpen)
    },
    handleObjectPanel () {
      this.objectPanelOpen = !this.objectPanelOpen
      this.$emit('handlePanelObject', this.objectPanelOpen)
    },
    handleObjectPanelGeometry (data) {
      this.handleObjectPanel()
      this.$emit('create', data)
    },
    handleBlockLayer (e) {
      this.$emit('blockLayer', e)
    },
    handleFilter (item) {
      this.handleObjectPanel()
      this.$emit('showFilters', item)
    },
    handleResetFilter (item) {
      this.$emit('resetFilter', item)
    }
  }
}
</script>

<style scoped>
  .icon-plus {
    background-image: url('../../plus-2.png');
    height: 30px;
    width: 30px;
    margin-top: 8px;
    margin-left: 9px;
  }
  .create-button {
    background-color: white;
    height: 44px !important;
    width: 44px !important;
    box-shadow: 2px 2px 5px 0 rgba(34, 60, 80, 0.34);
    border-radius: 30%;
    min-width: 0 !important;
  }
</style>