<template>
  <input
    class="slider"
    :value="value"
    type="range"
    :min="min"
    :max="max"
    @change="$emit('input', Number($event.target.value))"
  >
</template>

<script>
export default {
  name: 'RangeSlider',
  props: {
    min: Number,
    max: Number,
    value: Number
  },
  computed: {
    range () {
      return _.range(this.min, this.max + 1)
    }
  }
}
</script>

<style scoped>
  .slider {
    -webkit-appearance: none;
    /*width: 100%;*/
    height: 10px;
    border-radius: 5px;
    background: rgba(143, 208, 220, 0.75);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0592ce;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #009EDB;
    cursor: pointer;
  }
</style>