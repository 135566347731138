<template>
  <v-card
    class="pa-0"
    flat
    height="100%"
  >
    <Headers
      :layer="info.layer"
      :detail="showingData.detail"
      :is-geometry="isGeometry"
      :is-create="isCreate"
      :is-disable-interaction="isGeometry"
      :current-id-creating-map-object="currentIdCreatingMapObject"
      :is-info-passport-open="isInfoPassportOpen"
      :entity-geometry="entityGeometry"
      :is-object-panel="isPanelObject"
      @handlePanelObject="isPanelObject = $event"
      @handleInfoPassport="isInfoPassportOpen = $event"
      @navigation="toggleNavigation"
      @close="handleInfoClose"
      @hideItem="hideObjects"
      @blockLayer="blockLayer"
      @showFilters="showFilters"
      @resetFilter="clearFilter($event.eavEntity.id)"
      @create="toggleGeometryAppBar"
      @closeGeometry="closeTotalGeometryHeader"
      @closeCurrentDraw="closeCurrentDraw"
      :isOpeningObject="info.type === 'object'"
      :type-geometry="info.detail ? info.detail.geometry.type : ''"
      :current-active-edit-tool="currentActiveEditTool"
      :current-active-draw-tool="currentActiveDrawTool"
      @activateMove="activateMove"
      @activateVector="activateVector"
      @activateRotate="activateRotate"
      @activatePoint="activatePoint"
      @activateLineString="activateLineString"
      @activatePolygon="activatePolygon"
      @activateRectangle="activateRectangle"
      @activateCut="activateCut"
      @removeLastVertex="removeLastVertex"
      @cancelDrawObject="cancelDrawObject"
    />
    <l-map
      ref="map"
      :center.sync="center"
      :zoom.sync="zoom"
      :options="{zoomControl: false, drawControl: false}"
      :max-zoom="maxZoom"
      style="z-index: 0"
      id="main-map"
    >
      <PreloaderWindow :value="isLoading"/>
      <ReloadBtn v-if="!info.detail"/>
      <div class="controls-container">
        <l-control-zoom position="bottomright"></l-control-zoom>
        <div ref="measureControl"></div>
      </div>
    </l-map>
    <keep-alive :key="!info.detail">
      <MapNavigation
        v-model="navigationIsOpen"
        @loadTiles="preSave"
        @deleteTilesPassport="removeTile"
        @search:select="handleSearchSelect"
        v-if="!info.isLoading && !info.layer"
      />
    </keep-alive>
    <ConfirmTile
      v-if="isDialogTiles"
      :value="isDialogTiles"
      :detail="detailForTile"
      @confirm="confirmSave"
    />
    <MapObjectFilter
      v-if="isFiltersObjectsDialog && itemFilter"
      :is-dialog="isFiltersObjectsDialog"
      :value="itemFilter"
      :schema-object="itemFilter"
      :filter-object="info.filter[itemFilter.eavEntity.id]"
      @isDialog="isFiltersObjectsDialog = $event"
      @search="searchObjects"
      @clear="clearFilter"
    />
  </v-card>
</template>

<script>
import common from '@/components/mixins/map/common'
import mapInit from '@/components/mixins/map/helpers/mapInit'
import modelHandlers from '@/components/mixins/map/handlers/modelHandlers'
import mapStore from '@/components/mixins/map/store'
import workPanelMap from '@/components/mixins/workPanelMap'
import MapObjectFilter from '@/components/views/account/map/detail/mapPassportDetail/MapObjectFilter'
import MapNavigation from '@/components/views/mobile/map/detail/MapNavigation'
import Headers from '@/components/views/mobile/map/detail/Headers'
import PreloaderWindow from '../../../base/UI/PreloaderWindow'
import ReloadBtn from '@/components/base/UI/ReloadBtn'
import L from 'leaflet'
import ConfirmTile from '@/components/views/mobile/map/ConfirmTile'
import tilesMap from '@/components/mixins/mobile/map/tilesMap'
import mapDetail from '@/components/mixins/mobile/map/mapDetail'
import { maxZoom } from '@/config/common'
import controlLeaflet from '@/components/mixins/map/helpers/controlLeaflet'
import { App } from '@capacitor/app'

export default {
  mixins: [common, mapInit, modelHandlers, mapStore, workPanelMap, tilesMap, mapDetail, controlLeaflet],
  name: 'Detail',
  components: {
    MapNavigation,
    Headers,
    MapObjectFilter,
    ReloadBtn,
    PreloaderWindow,
    ConfirmTile
  },
  data () {
    return {
      navigationIsOpen: false,
      //todo: отрефакторить общий объект для создания объекта
      modelType: {
        type: null,
        entityId: null
      },
      isCreate: false,
      maxZoom,
      controlLayers: {
        measure: null,
        layers: null,
        tile: null,
        overlay: {},
        customTiles: null
      },
      isGeometry: false,
      currentIdCreatingMapObject: null,
      currentObjectName: null,
      isInfoPassportOpen: false,
      isPanelObject: false
    }
  },
  computed: {
    titleGeometryAppBar () {
      let result = 'Без названия'
      if (this.currentObjectName) {
        result = this.currentObjectName
      } else if (this.info?.detail?.properties.object_name) {
        result = this.info.detail.properties.object_name
      }
      return result
    },
    showingData () { // data для отображения в карточке
      return this.info.type === 'object' ? this.modelStatic.getParentDetail() : this.info
    }
  },
  watch: {
    itemActiveReport: {
      handler () {
        this.keyReportWindow += 1
      },
      deep: true
    },
    'showingData.detail.passportEntities': {
      handler () {
        if (this.showingData.detail) {
          this.initCart()
        }
      },
      deep: true
    },
    isShowDropdownContent: {
      handler () {
        this.changeStyleCancelBtn(this.isShowDropdownContent)
      }
    },
    isCreate: {
      handler () {
        this.$store.commit('map/changeCreateMode', this.isCreate)
      }
    }
  },
  methods: {
    initCart () {
      this.$store.commit('map/initCart', this.showingData.detail.properties.passportEntities.map(entity => entity.eavEntity.id))
    },
    toggleNavigation () {
      this.navigationIsOpen = !this.navigationIsOpen
    },
    //todo: совместить с _closeGeometry и разделить на блоки(методы)
    toggleGeometryAppBar (data) {
      this.isGeometry = !this.isGeometry
      if (data && data.hasOwnProperty('isCreate')) {
        data.item && this.$set(this, 'currentIdCreatingMapObject', data.item.id)
        this.isCreate = data.isCreate
        this.typeGeometryLayer = 'object'
        if (data.item) {
          this.modelType.entityId = data.item.eavEntity.id
        }
        this.modelType.type = data.type
        this.map.closePopup()
        this.$set(this.newConfigForCreate, 'item', data.item)
      } else if (this.isCreate) {
        this.typeGeometryLayer = 'passport'
        this.isCreate = false
        this.modelType.entityId = null
        this.modelType.type = null
        this.currentIdCreatingMapObject = null
      }
      let model = this.modelStatic.findModelByLayer(this.info.layer)
      if (this.isGeometry) {
        model.setEdit(true)
      } else {
        model.setEdit(false)
      }
      if (this.map.pm.globalDrawModeEnabled()) {
        this.map.pm.disableDraw()
      }
      if (this.currentActiveDrawTool === 'point' && this.info.detail.geometry.type === 'Point' && this.isGeometry && !this.currentIdCreatingMapObject) {
        this.activateMove()
      }
    }
  },
  beforeMount () {
    this.info.isLoading = true
  },
  async mounted () {
    this.scaleControl()
    this.initTileLayers()
    return this.$nextTick(async () => {
      await this.initModel()
      this.initMeasureControls()
      L.control
        .locate({
          drawCircle: false,
          flyTo: true,
          position: 'bottomright',
          initialZoomLevel: 17,
          class: 'custom-class',
          locateOptions: {
            watch: true
          }
        })
        .addTo(this.map)
      this.bottomRightBtn() //кнопка-портал

      this.map.on({
        'pm:create': this.handlePmCreate,
        'pm:drawstart': this.handleDrawStart,
        'edit:disable': this._closeGeometry,
        'polylinemeasure:toggle': this.rulerControl,
        'cut:finished': this.cutFinished
      })

      document.addEventListener('layer:startLoading', this.handleStartLoading)
      document.addEventListener('layer:endLoading', this.handleEndLoading)
      document.addEventListener('search:select', this.handleSearchSelect)
      document.addEventListener('layer:click', this.handleLayerClick)
      document.addEventListener('enableEditCustom', this.toggleGeometryAppBar)
      App.addListener('backButton', () => {
        if (this.navigationIsOpen) { //закрытие боковой панели паспортов
          this.navigationIsOpen = false
        } else if (this.isInfoPassportOpen) {
          this.isInfoPassportOpen = false
        } else if (this.isPanelObject) { // панель +
          this.isPanelObject = false
        }
      })
      this.addListenersPopup()
      return true
    })
  },
  async beforeDestroy () {
    this.map.off({
      'pm:create': this.handlePmCreate,
      'pm:drawstart': this.handleDrawStart,
      'edit:disable': this._closeGeometry,
      'polylinemeasure:toggle': this.rulerControl,
      'cut:finished': this.cutFinished
    })
    this.info.filter = {}
    document.removeEventListener('layer:startLoading', this.handleStartLoading)
    document.removeEventListener('layer:endLoading', this.handleEndLoading)
    document.removeEventListener('search:select', this.handleSearchSelect)
    document.removeEventListener('layer:click', this.handleLayerClick)
    document.removeEventListener('enableEditCustom', this.toggleGeometryAppBar)
    this.removeListenersPopup()
    await App.removeAllListeners()
  }
}
</script>

<style>
  /*кнопка местоположения*/
  .leaflet-control-locate a.leaflet-bar-part div.locate {
    background-image: url('./location-icon.svg');
  }
  .leaflet-control-locate a .leaflet-control-locate-location-arrow, .leaflet-control-locate.following a .leaflet-control-locate-location-arrow, .leaflet-control-locate-location-arrow {
    background-image: url('./location-icon.svg') !important;
    width: 44px;
    height: 44px;
    margin-top: 6px;
    margin-left: 6px;
  }
  .leaflet-bar-part-single {
    width: 30px !important;
    height: 30px !important;
  }
  .leaflet-draw-actions a {
    background: #000;
  }
  .leaflet-draw-actions a:hover {
    background: #000;
    opacity: 0.8;
  }
  .leaflet-bottom {
    z-index: 999 !important;
  }
  .leaflet-div-icon {
    border-radius: 50%;
    width: 3px !important;
    height: 3px !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
  }
  #map {
    height: 100%;
    position: relative;
  }
  .map-info {
    z-index: 99999999 !important;
  }
  .btn-chevron {
    position: fixed;
    top: 0;
    border-radius: 0 !important;
    background-color: #ffffff;
    z-index: 1;
    transition: margin-left 1s;
  }
  .collapseContent {
    margin-left: -40vw;
    transition: margin-left 1s;
  }
  .zeroingMargin {
    /*for collapse - for content and btn*/
    margin-left: 0;
    transition: margin-left 1s;
  }
  .noCollapseBtn {
    margin-left: 40vw;
    transition: margin-left 1s;
  }
  .cutMapInfo {
    height: 52px;
    overflow: hidden;
  }
</style>

<style scoped>
  .controls-container {
    position: absolute;
    bottom: 60px;
    right: 10px;
    display: flex;
  }
</style>
