import L, { Browser, CRS, Point, Util } from 'leaflet'
import { openDB } from 'idb'

//note оригинальный класс https://github.com/allartk/leaflet.offline/blob/master/src/TileManager.ts
const tileStoreName = 'tileStore'
let dbPromise

export function openTilesDataBase (urlTemplateIndex) {
  if (dbPromise) {
    return dbPromise
  }
  dbPromise = openDB(urlTemplateIndex, 2, {
    upgrade (db, oldVersion) {
      if (oldVersion < 1) {
        const tileStore = db.createObjectStore(tileStoreName, {
          keyPath: 'key'
        })
        tileStore.createIndex(urlTemplateIndex, 'urlTemplate')
      }
    }
  })
  return dbPromise
}

export async function getStorageLength (urlTemplateIndex) {
  const db = await openTilesDataBase(urlTemplateIndex)
  return db.count(tileStoreName)
}

const displacement = 0.50 // смещение
export function _tile2long (x, z) { // x + .. - смещение
  return ((x + displacement) / Math.pow(2, z) * 360 - 180)
}

export function _tile2lat (y, z) { // y + .. - смещение
  const n = Math.PI - 2 * Math.PI * (y + displacement) / Math.pow(2, z)
  return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))))
}

export function _latLngToBounds (lat, lng, zoom, width, height) { // for bbox
  function toRadians (degrees) {
    return degrees * Math.PI / 180
  }

  const EARTH_CIR_METERS = 40075016.686
  const degreesPerMeter = 360 / EARTH_CIR_METERS
  const metersPerPixelEW = EARTH_CIR_METERS / Math.pow(2, zoom + 8)
  const metersPerPixelNS = EARTH_CIR_METERS / Math.pow(2, zoom + 8) * Math.cos(toRadians(lat))

  const shiftMetersEW = width / 2 * metersPerPixelEW
  const shiftMetersNS = height / 2 * metersPerPixelNS

  const shiftDegreesEW = shiftMetersEW * degreesPerMeter
  const shiftDegreesNS = shiftMetersNS * degreesPerMeter

  return {
    south: lat - shiftDegreesNS,//широта юг
    west: lng - shiftDegreesEW,//долгота запад
    north: lat + shiftDegreesNS,//широта север
    east: lng + shiftDegreesEW//долгота восток
  }
}

export async function getStorageInfo (
  urlTemplate,
  urlTemplateIndex
) {
  const range = IDBKeyRange.only(urlTemplate)
  const db = await openTilesDataBase(urlTemplateIndex)
  return db.getAllFromIndex(tileStoreName, urlTemplateIndex, range)
}

export async function downloadTile (tileUrl) {
  const response = await fetch(tileUrl).catch((e) => {
    throw 'Error'
  })
  return await response.blob()
}

export async function saveTile (
  tileInfo,
  blob,
  urlTemplateIndex
) {

  const db = await openTilesDataBase(urlTemplateIndex)
  return db.put(tileStoreName, {
    blob,
    ...tileInfo
  })
}

export function getTileUrl (urlTemplate, data) {
  return Util.template(urlTemplate, {
    ...data,
    r: Browser.retina ? '@2x' : ''
  })
}

export function getTilePoints (area, tileSize) {
  const points = []
  if (!area.min || !area.max) {
    return points
  }
  const topLeftTile = area.min.divideBy(tileSize.x).floor()
  const bottomRightTile = area.max.divideBy(tileSize.x).floor()
  const isBigDifference = bottomRightTile.y - topLeftTile.y >= 10 || bottomRightTile.x - topLeftTile.x >= 10

  if (isBigDifference) {
    let chunkY = Math.max(topLeftTile.y + 10, bottomRightTile.y)
    let chunkX = Math.max(topLeftTile.x + 10, bottomRightTile.x)

    function recursed (startY, startX) {
      for (let j = startY; j <= chunkY; j += 1) {
        for (let i = startX; i <= chunkX; i += 1) {
          points.push(new Point(i, j))
        }
      }
      const oldChunkY = chunkY
      const oldChunkX = chunkX
      chunkY = Math.min(chunkY + 10, bottomRightTile.y)
      chunkX = Math.min(chunkX + 10, bottomRightTile.x)

      if (chunkY > bottomRightTile.y || chunkX > bottomRightTile.x) {
        setTimeout(() => {
          recursed(oldChunkY, oldChunkX)
        }, 100)
      }
    }

    recursed(topLeftTile.y, topLeftTile.x)
  } else {
    for (let j = topLeftTile.y; j <= bottomRightTile.y; j += 1) {
      for (let i = topLeftTile.x; i <= bottomRightTile.x; i += 1) {
        points.push(new Point(i, j))
      }
    }
  }
  return points
}

export function getStoredTilesAsJson (
  tileSize,
  tiles
) {
  const featureCollection = {
    type: 'FeatureCollection',
    features: []
  }
  for (let i = 0; i < tiles.length; i += 1) {
    const topLeftPoint = new Point(
      tiles[i].x * tileSize.x,
      tiles[i].y * tileSize.y
    )
    const bottomRightPoint = new Point(
      topLeftPoint.x + tileSize.x,
      topLeftPoint.y + tileSize.y
    )

    const topLeftlatlng = CRS.EPSG4326.pointToLatLng(topLeftPoint, tiles[i].z)
    const botRightlatlng = CRS.EPSG4326.pointToLatLng(
      bottomRightPoint,
      tiles[i].z
    )
    featureCollection.features.push({
      type: 'Feature',
      properties: tiles[i],
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [topLeftlatlng.lng, topLeftlatlng.lat],
            [botRightlatlng.lng, topLeftlatlng.lat],
            [botRightlatlng.lng, botRightlatlng.lat],
            [topLeftlatlng.lng, botRightlatlng.lat],
            [topLeftlatlng.lng, topLeftlatlng.lat]
          ]
        ]
      }
    })
  }

  return featureCollection
}

export async function removeTile (key, urlTemplateIndex) {
  const db = await openTilesDataBase(urlTemplateIndex)
  return db.delete(tileStoreName, key)
}

export async function getBlobByKey (key, urlTemplateIndex) {
  return (await openTilesDataBase(urlTemplateIndex))
    .get(tileStoreName, key)
    .then((result) => result && result.blob)
}

export async function hasTile (key, urlTemplateIndex) {
  const db = await openTilesDataBase(urlTemplateIndex)
  const result = await db.getKey(tileStoreName, key)
  return result !== undefined
}

export async function truncate (urlTemplateIndex) {
  return (await openTilesDataBase(urlTemplateIndex)).clear(tileStoreName)
}

export async function getTileImageSource (key, url, urlTemplateIndex) {
  const shouldUseUrl = !(await hasTile(key, urlTemplateIndex))
  if (shouldUseUrl) {
    return url
  }
  const blob = await getBlobByKey(key, urlTemplateIndex)
  return URL.createObjectURL(blob)
}