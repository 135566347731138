export { tileLayerOffline } from './TileLayerOffline'
export { savetiles } from './ControlSaveTiles'
export {
  getStorageInfo,
  getStorageLength,
  getStoredTilesAsJson,
  removeTile,
  truncate,
  downloadTile,
  saveTile,
  getBlobByKey
} from './TileManager'

export const tileSize = 256 // L.point(width=tileSize, height=tileSize)