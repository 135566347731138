<template>
  <v-app-bar
    flat
    color="white"
    absolute
    dense
    short
    bottom
    height="70"
  >
    <div
      class="d-flex flex-row align-center justify-space-between pl-1"
      style="width: 100%;"
    >
      <div class="d-flex flex-row">
        <div
          class="object-icon-block"
          :style="{'background-color':  currentCreatingObject.eavEntity.style.color, }"
          v-if="currentIdCreatingMapObject"
        >
          <v-icon
            :color=" 'white'"
          >{{ currentCreatingObject.eavEntity.icon }}
          </v-icon>
          <div class="object-icon-block-name text-clip pl-1 pr-1">{{ currentCreatingObject.eavEntity.entityName }}</div>
        </div>
        <v-icon
          v-if="isGeometry && !isCreate && !isNewObject"
          size="30"
          color="red"
          @click="isCancelObjectDialog = true"
          class="ml-2"
        >mdi-close
        </v-icon>
      </div>
      <div class="d-flex flex-row">
        <div
          class="d-flex flex-row justify-space-between"
          v-if="isGeometry && !isCreate"
        >
          <v-list-item
            :disabled="currentActiveEditTool==='move'"
            @click="$emit('activateMove')"
            class="pa-0 list_item"
            :class="['line', 'polygon'].includes(currentActiveDrawTool) ? 'list_item' : ''"
          >
            <v-icon
              color="darkgrey"
              size="30"
              :disabled="currentActiveEditTool==='move'"
            >mdi-drag-variant
            </v-icon>
          </v-list-item>
          <v-list-item
            v-if="typeGeometry !== 'Point'"
            :disabled="currentActiveEditTool==='vertex'"
            @click="$emit('activateVector')"
            class="pa-0 list_item"
            :class="['line', 'polygon'].includes(currentActiveDrawTool) ? 'list_item' : ''"
          >
            <v-icon
              color="darkgrey"
              size="30"
              :disabled="currentActiveEditTool==='vertex'"
            >mdi-vector-point-select
            </v-icon>
          </v-list-item>
          <v-list-item
            v-if="typeGeometry !== 'Point'"
            :disabled="currentActiveEditTool==='rotate'"
            @click="$emit('activateRotate')"
            class="pa-0 ml-2 mr-2"
          >
            <v-icon
              color="darkgrey"
              size="30"
              :disabled="currentActiveEditTool==='rotate'"
            >mdi-rotate-3d-variant
            </v-icon>
          </v-list-item>
          <v-list-item
            v-if="typeGeometry === 'Polygon' || typeGeometry === 'MultiPolygon'"
            :disabled="currentActiveEditTool==='cut'"
            @click="$emit('activateCut')"
            class="pa-0 ml-2 mr-2"
          >
            <v-icon
              color="darkgrey"
              size="30"
              :disabled="currentActiveEditTool==='cut'"
            >mdi-content-cut
            </v-icon>
          </v-list-item>
        </div>

        <div
          class="d-flex flex-row justify-space-between"
          v-else-if="isGeometry && isCreate"
        >
          <p
            class="ma-0"
            v-if="!availableTypesGeo.length"
          > нет доступных типов </p>
          <v-list-item
            :disabled="currentActiveDrawTool==='point'"
            @click="$emit('activatePoint')"
            v-if="availableTypesGeo.includes('Point')"
            class="pa-0 list_item"
            :class="['line', 'polygon'].includes(currentActiveDrawTool) ? 'list_item' : ''"
          >
            <v-icon
              color="darkgrey"
              size="30"
              :disabled="currentActiveDrawTool==='point'"
            >mdi-vector-point-select
            </v-icon>
          </v-list-item>
          <v-list-item
            v-if="availableTypesGeo.includes('LineString')"
            :disabled="currentActiveDrawTool==='line'"
            @click="$emit('activateLineString')"
            class="pa-0 list_item"
            :class="['line', 'polygon'].includes(currentActiveDrawTool) ? 'list_item' : ''"
          >
            <v-icon
              size="30"
              color="darkgrey"
              :disabled="currentActiveDrawTool==='line'"
            >mdi-vector-line
            </v-icon>
          </v-list-item>
          <v-list-item
            :disabled="currentActiveDrawTool==='polygon'"
            v-if="availableTypesGeo.includes('Polygon')"
            @click="$emit('activatePolygon')"
            class="pa-0 list_item"
            :class="['line', 'polygon'].includes(currentActiveDrawTool) ? 'list_item' : ''"
          >
            <v-icon
              size="30"
              color="darkgrey"
              :disabled="currentActiveDrawTool==='polygon'"
            >mdi-vector-polygon
            </v-icon>
          </v-list-item>
          <v-list-item
            v-if="availableTypesGeo.includes('Polygon')"
            :disabled="currentActiveDrawTool==='rectangle'"
            @click="$emit('activateRectangle')"
            class="pa-0 ml-2 mr-2"
          >
            <v-icon
              size="30"
              color="darkgrey"
              :disabled="currentActiveDrawTool==='rectangle'"
            >mdi-vector-rectangle
            </v-icon>
          </v-list-item>
        </div>

        <v-btn
          icon
          @click="$emit('removeLastVertex')"
          v-if="['line', 'polygon'].includes(currentActiveDrawTool) && isGeometry && isCreate"
        >
          <v-icon
            size="30"
            color="darkgrey"
          >
            mdi-arrow-left-top
          </v-icon>
        </v-btn>
      </div>
      <v-btn
        icon
        @click="(isGeometry && isCreate) ? $emit('close') : $emit('closeCurrentDraw')"
      >
        <v-icon
          size="30"
          :color="(isGeometry && isCreate) ? 'black' : '#0f76b4'"
        >{{ (isGeometry && isCreate) ? 'mdi-close' : 'mdi-content-save-move-outline' }}
        </v-icon>
      </v-btn>
    </div>
    <BaseDialog
      max-width="400"
      label="Стереть объект?"
      is-confirm-btn
      with-label
      v-model="isCancelObjectDialog"
      is-cancel-btn
      @confirm="confirmCancelObject"
    ></BaseDialog>
  </v-app-bar>
</template>

<script>
import BaseDialog from '@/components/base/mobile/BaseDialog'

export default {
  name: 'GeometryHeaders',
  components: { BaseDialog },
  props: {
    detail: Object,
    currentIdCreatingMapObject: [Number, String],
    isGeometry: Boolean,
    isCreate: Boolean,
    currentActiveEditTool: String,
    currentActiveDrawTool: String,
    typeGeometry: String,
    isNewObject: Boolean,
    availableTypesGeo: {
      type: Array
    }
  },
  data () {
    return {
      isCancelObjectDialog: false
    }
  },
  methods: {
    confirmCancelObject () {
      this.$emit('cancelDrawObject')
      this.isCancelObjectDialog = false
    }
  },
  computed: {
    innerWidth () {
      return window.innerWidth
    },
    currentCreatingObject () {
      return this.currentIdCreatingMapObject ?
        this.detail.properties.passportEntities.find((item) => item.id === this.currentIdCreatingMapObject || item.eavEntity.id === this.currentIdCreatingMapObject) :
        null
    }
  }
}
</script>

<style scoped>
  .v-toolbar > /deep/ .v-toolbar__content, .v-toolbar__extension {
    padding: 0 !important;
  }
  .object-icon-block {
    border-radius: 5px;
    width: 30px;
  }
  .v-list-item {
    padding: 0 !important;
  }
  .theme--light.v-list-item--disabled, .theme--light.v-icon.v-icon.v-icon--disabled, .active-item > i {
    color: #52a8e3 !important;
  }
  .list_item {
    margin-left: 8px;
    margin-right: 16px;
  }
  @media (max-width: 320px) {
    .list_item {
      margin-left: 2px;
      margin-right: 4px;
    }
  }
</style>

<style scoped>
  .v-toolbar > /deep/ .v-toolbar__content, .v-toolbar__extension {
    padding: 0 !important;
  }
  .object-icon-block {
    border-radius: 5px;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .text-clip {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 60px;
    font-size: 10px;
  }
</style>