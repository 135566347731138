<template>
  <v-app-bar
    flat
    color="black"
    class="rounded-t-0 rounded-b-xl"
    absolute
    dense
    short
  >
    <div
      class="d-flex flex-row align-center justify-space-between"
      style="width: 100%;"
    >
      <v-btn
        color="white"
        icon
        @click="toggleMenu"
        class="mr-auto"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn
        color="white"
        icon
        @click="isLogoutWindow = true"
        class="ml-auto"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <BaseDialog
        max-width="200"
        v-model="isLogoutWindow"
        is-confirm-btn
        is-cancel-btn
        @confirm="logout"
        label="Выйти?"
        with-label
      ></BaseDialog>
    </div>
  </v-app-bar>

</template>

<script>

import BaseDialog from '@/components/base/mobile/BaseDialog'
import { deleteSystemFile } from '@/components/utils/mobile/filesystemInteraction'

export default {
  name: 'MapHeader',
  components: { BaseDialog },
  data () {
    return {
      isLogoutWindow: false
    }
  },
  methods: {
    logout () {
      deleteSystemFile('profile/p')
      deleteSystemFile('profile/l')
      this.$store.dispatch('auth/logout')
    },
    toggleMenu () {
      this.$emit('navigation')
    }
  }
}
</script>

<style scoped>

</style>