import L, { DomEvent, TileLayer, Util } from 'leaflet'
import {
  _latLngToBounds,
  _tile2lat,
  _tile2long,
  getTileImageSource,
  getTilePoints,
  getTileUrl,
  hasTile
} from './TileManager'
import { urlTileESRI, urlTileGoogleWithParams, urlTileQgsWithParams } from '@/config/common'
import { tileSize } from '@/plugins/leaflet.offline/index'
import { maxZoom } from '@/config/common'
import store from '@/store'

const tilesByLayer = {
  'OpenStreetMap': urlTileQgsWithParams + '&version=1.3.0',
  'Google': urlTileGoogleWithParams,
  'ESRI': urlTileESRI
}

//note оригинальный класс https://github.com/allartk/leaflet.offline/blob/master/src/TileLayerOffline.ts
export class TileLayerOffline extends TileLayer {
  _url = tilesByLayer[this.options.baseTile]
  bbox = ''
  maxZoom = maxZoom
  x = ''
  y = ''
  z = ''

  createTile (coords, done) {
    const tile = document.createElement('img')

    DomEvent.on(tile, 'load', Util.bind(this._tileOnLoad, this, done, tile))
    DomEvent.on(tile, 'error', Util.bind(this._tileOnError, this, done, tile))

    if (this.options.crossOrigin || this.options.crossOrigin === '') {
      tile.crossOrigin =
        this.options.crossOrigin === true ? '' : this.options.crossOrigin
    }
    if (typeof this.options.referrerPolicy === 'string') {
      tile.referrerPolicy = this.options.referrerPolicy
    }
    tile.alt = ''

    tile.setAttribute('role', 'presentation')

    const long = _tile2long(coords.x, coords.z)
    const lat = _tile2lat(coords.y, coords.z)
    const bb = _latLngToBounds(lat, long, coords.z, tileSize, tileSize)

    this.options.bbox = [bb.south, bb.west, bb.north, bb.east].join(',')
    getTileImageSource(
      this._getStorageKey(coords),
      this.getTileUrl(coords),
      this.options.passportId
    ).then((src) => (tile.src = src))
    return tile
  }

  _getStorageKey (coords) {
    return getTileUrl(this._url, {
      ...coords,
      ...this.options,
      s: this.options.subdomains['0']
    })
  }

  async getTileUrls (bounds, zoom) {
    this._url = tilesByLayer[this.options.baseTile]
    const tiles = []
    const tilePoints = getTilePoints(bounds, this.getTileSize())
    let chunk = Math.min(10, tilePoints.length)
    const self = this
    store.commit('queueDownload/changeTileProcess', { count: tilePoints.length })

    function recursed (startChunk) {
      for (let index = startChunk; index < chunk; index += 1) {
        store.commit('queueDownload/changeTileProcess', { current: 1 })
        const tilePoint = tilePoints[index]
        const long = _tile2long(tilePoint.x, zoom)
        const lat = _tile2lat(tilePoint.y, zoom)
        const bb = _latLngToBounds(lat, long, zoom, tileSize, tileSize)
        // const bbox = [bb.west, bb.south, bb.east, bb.north].join(',') //for 1.1.1
        const bbox = [bb.south, bb.west, bb.north, bb.east].join(',')
        self.x = tilePoint.x
        self.y = tilePoint.y
        self.z = zoom
        const data = {
          ...self.options,
          bbox,
          x: self.x,
          y: self.y,
          z: self.z
        }
        tiles.push({
          key: getTileUrl(self._url, {
            ...data,
            s: self.options.subdomains?.[0]
          }),
          url: getTileUrl(self._url, {
            ...data,
            s: self._getSubdomain(tilePoint)
          }),
          urlTemplate: self._url,
          createdAt: Date.now()
        })
      }

      const oldChunk = chunk
      chunk = Math.min(chunk + 10, tilePoints.length)
      if (oldChunk < chunk) {
        setTimeout(() => {
          recursed(oldChunk)
        }, 100)
      } else {
        return true
      }
    }

    recursed(0)

    const wait = () => { //ждем выполнения всех timeout, чтобы заполнился массив с тайлами
      return new Promise((resolve) => {
        setTimeout(() => {
          const condition = tiles.length === tilePoints.length
          if (condition) {
            resolve()
          } else {
            wait().then(resolve)
          }
        }, 1000)
      })
    }
    tiles.length !== tilePoints.length && await wait()
    return tiles
  }
}

export function tileLayerOffline (url, options) {
  return new TileLayerOffline(url, options)
}

if (window.L) {
  window.L.tileLayer.offline = tileLayerOffline
}