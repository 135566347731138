<template>
  <div>
    <PassportHeader
      v-if="layer && !isGeometry && !isOpeningObject && !currentIdCreatingMapObject"
      :detail="detail"
      :is-disable-interaction="isDisableInteraction"
      :current-id-creating-map-object="currentIdCreatingMapObject"
      @close="handleClose"
      @create="handleObjectPanelGeometry"
      @hideItem="$emit('hideItem', $event)"
      @showFilters="$emit('showFilters', $event)"
      @resetFilter="$emit('resetFilter', $event)"
      @blockLayer="$emit('blockLayer', $event)"
      @handleInfoPassport="$emit('handleInfoPassport', $event)"
      @handlePanelObject="$emit('handlePanelObject', $event)"
      :isOpeningObject="isOpeningObject"
      :is-info-passport-open="isInfoPassportOpen"
      :is-object-panel="isObjectPanel"
    />
    <MapHeader
      v-else-if="!isGeometry && !isOpeningObject && !currentIdCreatingMapObject"
      @navigation="toggleMenu"
    />
    <GeometryHeaders
      :available-types-geo="availableTypes"
      v-if="isGeometry && (isOpeningObject || currentIdCreatingMapObject) && layer"
      :detail="detail"
      :is-new-object="!!layer.feature.properties.id"
      :currentIdCreatingMapObject="currentIdCreatingMapObject || layer.feature.properties.eav_entity_id"
      :is-create="isCreate"
      :is-geometry="isGeometry"
      :type-geometry="typeGeometry"
      :current-active-edit-tool="currentActiveEditTool"
      :current-active-draw-tool="currentActiveDrawTool"
      @closeCurrentDraw="$emit('closeCurrentDraw')"
      @close="$emit('closeGeometry')"
      @activateMove="$emit('activateMove')"
      @activateVector="$emit('activateVector')"
      @activateRotate="$emit('activateRotate')"
      @activatePoint="$emit('activatePoint')"
      @activateLineString="$emit('activateLineString')"
      @activatePolygon="$emit('activatePolygon')"
      @activateRectangle="$emit('activateRectangle')"
      @removeLastVertex="$emit('removeLastVertex')"
      @cancelDrawObject="$emit('cancelDrawObject')"
      @activateCut="$emit('activateCut')"
    />
  </div>
</template>

<script>
import MapHeader from '@/components/views/mobile/map/detail/headers/MapHeader'
import PassportHeader from '@/components/views/mobile/map/detail/headers/PassportHeader'
import GeometryHeaders from '@/components/views/mobile/map/detail/headers/GeometryHeaders'

export default {
  name: 'Headers',
  components: { GeometryHeaders, PassportHeader, MapHeader },
  props: {
    detail: Object,
    layer: Object,
    disabled: Boolean,
    isDisableInteraction: Boolean,
    currentIdCreatingMapObject: Number,
    isGeometry: Boolean,
    isCreate: Boolean,
    isOpeningObject: Boolean,
    currentActiveEditTool: String,
    currentActiveDrawTool: String,
    typeGeometry: String,
    isInfoPassportOpen: Boolean,
    isObjectPanel: Boolean,
    entityGeometry: [String, null]
  },
  computed: {
    availableTypes () {
      return this.entityGeometry ? this.entityGeometry.split(',') : []
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('navigation')
    },
    handleClose () {
      this.$emit('close')
    },
    handleObjectPanelGeometry (data) {
      if (!this.isGeometry) {
        this.$emit('create', data)
      }
    }
  }
}
</script>

<style scoped>

</style>