<template>
  <v-navigation-drawer
    v-model="internalValue"
    absolute
    width="85vw"
    class="navigation"
  >
    <v-card
      flat
      class="transparent pa-3"
    >
      <div class="mb-2 d-flex justify-space-between">
        <p class="ma-0 grey--text subtitle-1 text--darken-2 font-weight-bold">Список территорий</p>
        <BaseBtn
          small
          :icon="isDialogFilter ? 'mdi-magnify-close' : 'mdi-magnify'"
          @click="isDialogFilter = !isDialogFilter"
          :loading="isLoading"
          isTypeIcon
          color-icon="grey darken-2"
        />
      </div>
      <div v-if="isDialogFilter">
        <BaseFilter
          @handleSearch="handleSearch"
          @clearFilter="handleClear"
          :is-additional-styles="false"
        >
          <template #filters>
            <FilterPassport
              :changes-fields="changesFields"
              solo-inverted
              hide-details
              @change="changeField"
            />
          </template>
        </BaseFilter>
      </div>
      <v-list
        class="pa-0 white"
        dense
        light
        nav
      >
        <v-list-item-group
          v-if="searchModel.items && searchModel.items.models"
          v-model="currentActiveItem"
          light
        >
          <ListItem
            v-for="(item, index) in searchModel.items.models"
            :key="index"
            :item="item"
            @savePassport="savePassportInSystem"
            @select="handleSelect"
            @deletePassport="deletePassportFromSystem"
            @loadTiles="$emit('loadTiles', $event)"
            @deleteTilesPassport="$emit('deleteTilesPassport', $event)"
          />
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import BaseSideBar from '@/components/base/BaseSideBar'
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseFilter from '@/components/base/BaseFilter'
import FilterPassport from '@/components/views/account/passport/common/FilterPassport'
import { verificationScenarioByRole } from '@/components/utils/common'
import { emitEvent } from '@/components/utils/map/common'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import {
  deleteSystemFile,
  readSystemFile,
  readSystemFilesInFolder,
  writeSystemFile
} from '@/components/utils/mobile/filesystemInteraction'
import { isNeedOfflineMode, isOffline } from '@/components/utils/mobile/common'
import selectFirst from '@/components/mixins/map/selectFirst'

const ListItem = () => {
  if (isMobileDevice()) {
    return import('@/components/views/mobile/map/mapPassportNavigationSideBar/ListItem')
  } else {
    return import('@/components/views/account/map/detail/mapPassportNavigationSideBar/ListItem')
  }
}

export default {
  name: 'MapNavigation',
  props: {
    value: Boolean
  },
  mixins: [selectFirst],
  components: { BaseSideBar, BaseBtn, ListItem, BaseFilter, FilterPassport },
  data () {
    return {
      isLoading: false,
      currentActiveItem: null,
      isDialogFilter: false,
      changesFields: {},
      searchModel: {
        query: {
          filter: {
            verificationScenario: verificationScenarioByRole()
          },
          options: {}
        },
        moduleName: 'passport',
        items: null
      }
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    async checkPassports () {
      const path = 'map/passports/'
      const files = await readSystemFilesInFolder(path)
      if (files) {
        await Promise.all(files?.files?.map(async (item) => {
          const file = await readSystemFile(path + item.name) // объект паспорта
          if (!this.searchModel.items?.models) {
            this.searchModel.items = {
              models: []
            }
          }
          this.searchModel.items?.models?.push(JSON.parse(file))
        }))
      }
    },
    async savePassportInSystem (item) { // паспорт для навигации и отрисовке на карте
      const path = 'map/passports/' + item.properties.id
      await writeSystemFile(path, item)
    },
    async deletePassportFromSystem (item) {
      const file = await readSystemFile('map/passports/' + item.properties.id)
      if (file) {
        await deleteSystemFile('map/passports/' + item.properties.id).then(() => this.$store.dispatch('systemMessages/info', {
          text: 'удалено',
          time: 500
        }))
      }
      return true
    },
    handleSelect (data) {
      emitEvent('search:select', data)
    },
    //todo: синхронизировать это с загрузкой слоев, сейчас 2 одинаковых запроса летят
    async handleSearch () {
      this.$set(this.searchModel.query, 'filter', { ...this.filter, ...this.changesFields })
      if (!this.isLoading) {
        this.isLoading = true
        this.searchModel.items = await this.$store.dispatch('server/get', {
          url: '/' + this.searchModel.moduleName,
          params: this.searchModel.query
        }) || { models: [], count: 0 }
        this.isLoading = false
        this.selectFirst()
      }
      return true
    },
    handleClear () {
      for (let key in this.changesFields) {
        this.$delete(this.searchModel.query.filter, key)
      }
      this.$set(this, 'changesFields', {})
      this.handleSearch()
      this.isDialogFilter = !this.isDialogFilter
    },
    changeField (data) {
      this.$set(this, 'changesFields', data)
    }
  },
  async mounted () {
    if (await isNeedOfflineMode()) {
      await this.checkPassports()
      this.selectFirst()
    } else {
      this.$nextTick(async () => {
        await this.handleSearch()
      })
    }
  }
}
</script>

<style scoped>
  .navigation {
    border-radius: 0 25px 25px 0;
    background: linear-gradient(170deg, #FFF 6.92%, #FFF8FF 32.76%, #DEF7FF 90.07%);
  }
  @media (max-width: 350px) {
    .navigation {
      width: 100vw !important;
    }
  }
</style>
