import { savetiles, tileSize } from '@/plugins/leaflet.offline'
import { urlTileESRI, urlTileGoogleWithParams, urlTileQgs } from '@/config/common'
import L from 'leaflet'
import { readSystemFile, writeSystemFile } from '@/components/utils/mobile/filesystemInteraction'
import { Network } from '@capacitor/network'
import { deleteDB } from 'idb'

export default {
  data () {
    return {
      isDialogTiles: false,
      layers: 'OpenStreetMap', //это для загрузки в оффлайн
      detailForTile: null,
      zoomlevels: [16, 17, 18, 19, 20, 21, 22, 23, 24]
    }
  },
  methods: {
    async setLayers () {// используется после открытия паспорта, чтобы подгрузить нужные слои для оффлайн тайлов
      const config = JSON.parse(await readSystemFile('map/passport/tiles/' + this.info.detail.properties.id))
      if (config?.layers) {
        this.layers = config.layers
      }
      return true
    },
    preSave (detail) {
      this.isDialogTiles = true
      this.detailForTile = detail
    },
    async confirmSave ({ layer, zooms }) {
      if (layer) {
        this.layers = layer
      }
      this.isDialogTiles = false
      this.zoomlevels = zooms
      await this.savePassportTile(this.detailForTile)
    },
    async addOfflineTile (detail) {
      const baseLayerTile = this.layers.split(',')[0]
      const tiles = {
        'OpenStreetMap': urlTileQgs + '&version=1.3.0',
        'Google': urlTileGoogleWithParams,
        'ESRI': urlTileESRI
      }
      const maxZoomsForTile = {
        'OpenStreetMap': 19,
        'Google': 21,
        'ESRI': 19
      }
      const urlTemplate = tiles[baseLayerTile]
      const localDetail = detail || this.info.detail
      const zoomlevels = this.zoomlevels
      const maxNativeZoom = maxZoomsForTile[baseLayerTile] > zoomlevels[zoomlevels.length - 1] ? zoomlevels[zoomlevels.length - 1] : maxZoomsForTile[baseLayerTile]
      const configTile = {
        passportId: localDetail.properties.id,
        service: 'WMS',
        request: 'GetMap',
        layers: this.layers,
        format: 'image%2Fjpeg',
        transparent: 'false',
        version: '1.3.0',
        width: tileSize,
        height: tileSize,
        srs: 'EPSG%3A4326',
        baseTile: baseLayerTile
      }
      const baseLayer = L.tileLayer.offline(urlTemplate, {
        ...configTile,
        minZoom: zoomlevels[0],
        maxZoom: zoomlevels[zoomlevels.length - 1],
        crossOrigin: null,
        maxNativeZoom: maxNativeZoom
      })
      baseLayer.addTo(this.map)
      await writeSystemFile('map/passport/tiles/' + localDetail.properties.id, configTile)
      return baseLayer
    },
    async savePassportTile (detail) {
      const localDetail = detail || this.info.detail
      if ((await Network.getStatus()).connected) {
        const geoJson = L.geoJSON({ type: 'Feature', ...localDetail.geometry })
        const bounds = geoJson.getBounds()
        // const urlTemplate = urlTileQgs + '&version=1.3.0'
        const zoomlevels = this.zoomlevels //всегда должно быть два значения и только по порядку! Либо менять потом отдельно minZoom, maxZoom ⬇

        const baseLayer = await this.addOfflineTile(detail)

        const self = this
        const saveTilesControl = savetiles(baseLayer, {
          zoomlevels: zoomlevels,
          bounds: bounds,
          urlTemplateIndex: localDetail.properties.id,
          crossOrigin: null,
          async confirm (layer, successCallback) {
            if ((await Network.getStatus()).connected) {
              successCallback()
            }
          },
          confirmRemoval (layer, successCallback) {
            successCallback()
          },
          downLoaded () {
            self.$store.commit('queueDownload/finishTile')
            self.$store.commit('queueDownload/finishDownload') // очередь загрузки текущего паспорта
          }
        })
        saveTilesControl.addTo(this.map)
      } else {
        await this.addOfflineTile(detail)
      }
      return true
    },
    async removeTile (detail) { //удаление тайлов по паспорту
      await deleteDB(detail.properties.id)
    }
  }
}