<template>
  <v-alert
    height="60"
    width="60"
    color="grey"
    class="reload_alert d-flex flex-row ma-0 pa-0 ml-3 mb-3"
  >
    <BaseBtn
      icon="mdi-reload"
      color="green"
      :disabled="disable"
      height="60"
      color-icon="white"
      class="reload_btn"
      @click="reload"
    />
  </v-alert>
</template>

<script>
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  components: { BaseAlert, BaseBtn },
  name: 'ReloadBtn',
  data () {
    return {
      disable: false
    }
  },
  methods: {
    reload () {
      this.disable = true //делаю дизейбл на 4 сек, чтобы юзер нажимал 100 раз
      location.reload()
      setTimeout(() => {
        this.disable = false
      }, 4000)
    }
  }
}
</script>

<style scoped>
  .reload_alert {
    position: absolute;
    z-index: 1000;
    bottom: 0;
  }
  .reload_btn {
    margin-left: 0 !important;
  }
</style>