import L, {
  Control,
  DomEvent,
  DomUtil,
  bounds
} from 'leaflet'
import {
  getStorageLength,
  downloadTile,
  saveTile,
  hasTile
} from './TileManager'
import { maxZoom } from '@/config/common'
import store from '@/store'

//note оригинальный класс https://github.com/allartk/leaflet.offline/blob/master/src/ControlSaveTiles.ts
export class ControlSaveTiles extends Control {
  _map
  _refocusOnMap
  _baseLayer
  // options
  status = {
    storagesize: 0,
    lengthToBeSaved: 0,
    lengthSaved: 0,
    lengthLoaded: 0,
    _tilesforSave: []
  }

  constructor (baseLayer, options) {
    super(options)
    this._baseLayer = baseLayer
    this.setStorageSize()
    this.options = {
      ...{
        position: 'topleft',
        saveText: '+',
        rmText: '-',
        maxZoom: maxZoom,
        saveWhatYouSee: false,
        bounds: null,
        confirm: null,
        downLoaded: null,
        confirmRemoval: null,
        parallel: 50,
        zoomlevels: undefined,
        alwaysDownload: true,
        urlTemplateIndex: null
      },
      ...options
    }
  }

  setStorageSize () {
    if (this.status.storagesize) {
      return Promise.resolve(this.status.storagesize)
    }
    return getStorageLength(this.options.urlTemplateIndex)
      .then((numberOfKeys) => {
        this.status.storagesize = numberOfKeys
        this._baseLayer.fire('storagesize', this.status)
        return numberOfKeys
      })
      .catch(() => 0)
  }

  getStorageSize (callback) {
    this.setStorageSize().then((result) => {
      if (callback) {
        callback(result)
      }
    })
  }

  setLayer (layer) {
    this._baseLayer = layer
  }

  onAdd () {
    const container = DomUtil.create('div', 'savetiles leaflet-bar')
    this._saveTiles(this.options.urlTemplateIndex)
    return container
  }

  _createButton (
    html,
    className,
    container,
    fn
  ) {
    const link = DomUtil.create('a', className, container)
    link.innerHTML = html
    link.href = '#'
    link.ariaRoleDescription = 'button'

    DomEvent.on(link, 'mousedown dblclick', DomEvent.stopPropagation)
      .on(link, 'click', DomEvent.stop)
      .on(link, 'click', fn, this)

    return link
  }

  async _saveTiles (urlTemplateIndex) {
    let tiles = await this._calculateTiles()
    tiles = tiles.filter((item) => item)
    this._resetStatus(tiles)
    const successCallback = async () => {
      this._baseLayer.fire('savestart', this.status)
      const loader = async () => {
        const tile = tiles.shift()
        if (tile === undefined) {
          return Promise.resolve()
        }
        const blob = await this._loadTile(tile).catch(e => {
          store.commit('queueDownload/changeTileProcess', { current: 0, count: 0 })
          this.options.downLoaded()
          tiles = []
          return Promise.reject()
        })
        if (blob) {
          await this._saveTile(tile, blob, urlTemplateIndex)
        }
        if (tiles.length) {
          return loader()
        } else {
          return Promise.resolve()
        }
      }
      const parallel = Math.min(tiles.length, this.options.parallel)
      for (let i = 0; i < parallel; i += 1) {
        if (!tiles.length) {
          i = parallel
        }
        await loader()
      }
      this.options.downLoaded()
    }
    if (this.options.confirm) {
      this.options.confirm(this.status, successCallback)
    } else {
      successCallback()
    }
  }

  async _calculateTiles () {
    let tiles = []
    const minZoom = 5// minimum zoom to prevent the user from saving the whole world
    let zoomlevels = []// current zoom or zoom options

    if (this.options.saveWhatYouSee) {
      const currentZoom = this._map.getZoom()
      if (currentZoom < minZoom) {
        throw new Error(
          `It's not possible to save with zoom below level ${minZoom}.`
        )
      }
      const { maxZoom } = this.options

      for (let zoom = currentZoom; zoom <= maxZoom; zoom += 1) {
        zoomlevels.push(zoom)
      }
    } else {
      zoomlevels = this.options.zoomlevels || [this._map.getZoom()]
    }
    const latlngBounds = this.options.bounds || this._map.getBounds()
    for (let i = 0; i < zoomlevels.length; i += 1) {
      const area = bounds(
        this._map.project(latlngBounds.getNorthWest(), zoomlevels[i]),
        this._map.project(latlngBounds.getSouthEast(), zoomlevels[i])
      )
      const urls = await this._baseLayer.getTileUrls(area, zoomlevels[i])
      tiles = tiles.concat(urls)
    }
    return tiles
  }

  _resetStatus (tiles) {
    this.status = {
      lengthLoaded: 0,
      lengthToBeSaved: tiles.length,
      lengthSaved: 0,
      _tilesforSave: tiles,
      storagesize: this.status.storagesize
    }
  }

  async _loadTile (tile) {
    let blob
    if (
      this.options.alwaysDownload === true ||
      (await hasTile(tile.key, this.options.urlTemplateIndex)) === false
    ) {
      blob = await downloadTile(tile.url).catch((e) => {
        store.dispatch('systemMessages/error', { text: 'Не удачная загрузка тайлов. Скачайте заново.' })
        throw 'Error'
      })
      this.status.lengthLoaded += 1
    }
    this.status.lengthLoaded += 1

    this._baseLayer.fire('loadtileend', this.status)
    if (this.status.lengthLoaded === this.status.lengthToBeSaved) {
      this._baseLayer.fire('loadend', this.status)
    }
    return blob
  }

  async _saveTile (tile, blob, urlTemplateIndex) {
    await saveTile(tile, blob, urlTemplateIndex)
    this.status.lengthSaved += 1
    this._baseLayer.fire('savetileend', this.status)
    if (this.status.lengthSaved === this.status.lengthToBeSaved) {
      this._baseLayer.fire('saveend', this.status)
      this.setStorageSize()
    }
  }
}

export function savetiles (
  baseLayer,
  options
) {
  return new ControlSaveTiles(baseLayer, options)
}


if (window.L) {
  window.L.control.savetiles = savetiles
}