<template>
  <BaseDialog
    max-width="500"
    v-model="value"
  >
    <template #content>
      <v-card-title class="pl-0">Выберите слои(подложки) для карты</v-card-title>
      <p class="mb-0">Основная:</p>
      <v-checkbox
        hide-details
        v-for="item in baseTiles"
        :label="item.label"
        :value="item.tile"
        :key="item.tile"
        class="pa-0 ma-0"
        v-model="baseTile"
        dense
      ></v-checkbox>
      <p
        class="mb-0 mt-2"
        v-if="isShowAdditional"
      >Дополнительная:</p>
      <v-checkbox
        hide-details
        v-for="item in tiles"
        :label="item.tile"
        v-model="extraTile"
        :value="item.tile"
        :key="item.tile"
        class="pa-0 ma-0"
        dense
      ></v-checkbox>
      <div>
        <p class="ma-0 mt-2 mb-1">Диапазон зума<span class="recommended">(выставлены рекомендуемые значения)</span>:</p>
        От({{ rangeFrom }}):
        <RangeSlider
          class="mr-3"
          :value="rangeFrom"
          @input="handleRangeFrom"
          :min="16"
          :max="maxRange"
        />
        До({{ rangeTo }}):
        <RangeSlider
          :value="rangeTo"
          @input="rangeTo = $event"
          :min="rangeFrom"
          :max="maxRange"
        />
      </div>
      <BaseBtn
        @click="confirmTile"
        title="подтвердить"
        small
        color="primary"
        class="mt-2"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/mobile/BaseDialog'
import BaseBtn from '@/components/base/UI/BaseBtn'
import RangeSlider from '@/components/base/UI/RangeSlider'

export default {
  name: 'ConfirmTile',
  props: {
    value: Boolean,
    detail: Object
  },
  components: { BaseBtn, BaseDialog, RangeSlider },
  data () {
    return {
      baseTile: 'OpenStreetMap',
      extraTile: null,
      baseTiles: [
        { tile: 'OpenStreetMap', label: 'Схема ОСМ' },
        {
          tile: 'Google',
          label: 'Google спутник'
        },
        { tile: 'ESRI', label: 'ESRI Satellite' }],
      tiles: [],
      rangeFrom: 16,
      rangeTo: 20,
      maxRange: 21
    }
  },
  watch: {
    baseTile: {
      handler () {
        if (this.baseTile === 'Google' || this.baseTile === 'OpenStreetMap') {
          this.maxRange = 21
          if (this.rangeTo > 21) {
            this.rangeTo = 21
          }
        } else if (this.baseTile === 'ESRI') {
          this.maxRange = 19
          if (this.rangeTo > 19) {
            this.rangeTo = 19
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    layer () {
      const extraTile = this.extraTile ? ',' + this.extraTile : ''
      return this.baseTile + extraTile
    },
    isShowAdditional () {
      return !!this.tiles?.length
    }
  },
  methods: {
    handleRangeFrom (value) {
      this.rangeFrom = value
      if (this.rangeTo < value) {
        this.rangeTo = value
      }
    },
    async getTiles () {
      let tiles = await this.$store.dispatch('server/get', {
        url: 'map/layers?project_id=' + this.detail.properties.project_id + '&is_added=1'
      })
      if (tiles?.models) {
        tiles.models.forEach(tile => {
          this.tiles.push({ tile: tile.label, label: tile.label })
        })
      }
    },
    confirmTile () {
      const zooms = _.range(this.rangeFrom, this.rangeTo + 1)
      this.$emit('confirm', { layer: this.layer, zooms })
    }
  },
  async mounted () {
    this.value && await this.getTiles()
  }
}
</script>

<style scoped>
  .recommended {
    color: #009EDB;
  }
</style>