<template>
  <BaseCard>
    <template #header>
      <v-card-title class="font-weight-bold custom-card-title">{{ title }}</v-card-title>
    </template>
    <template #content>
      <v-row>
        <v-col class="subtitle-2 text--darken-2 grey--text wrapper-items">
          <div>
            <b>{{ $t('passport.cadastr_number') + ': ' }}</b>
            <p>{{ value.properties.cadastr_number || '–' }} </p>
          </div>
          <div>
            <b>{{ $t('passport.length') + ': ' }}</b>
            <p>{{ value.properties.length || '–' }} </p>
          </div>
          <div>
            <b>{{ $t('passport.width') + ': ' }}</b>
            <p>{{ value.properties.width || '–' }} </p>
          </div>
          <div>
            <b>{{ $t('passport.area') + ': ' }}</b>
            <p>{{ value.properties.area || '–' }} </p>
          </div>
          <div>
            <b>{{ $t('passport.origin_passportization_date') + ': ' }}</b>
            <p>{{ timestampToDate(value.properties.origin_passportization_date) || '–' }} </p>
          </div>
          <div>
            <b>{{ $t('base.updated_at') + ': ' }}</b>
            <p>{{ timestampToDate(value.properties.updated_at) || '–' }} </p>
          </div>
          <div
            v-for="(item, key) in infoProperties"
            :key="key"
            v-if="item"
          >
            <b>{{ $t('passport.' + key) + ': ' }}</b>
            <p>{{ item || '–' }} </p>
          </div>
        </v-col>
      </v-row>
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/base/BaseCard'
import formatters from '@/components/mixins/formatters'
import _ from 'lodash'
import InfoArea from '@/components/views/account/map/detail/mapPassportDetail/passportInfo/InfoArea'

export default {
  name: 'PassportInfo',
  mixins: [formatters],
  components: { InfoArea, BaseCard },
  props: {
    value: Object,
    title: String
  },
  computed: {
    infoProperties () { //создание объекта по указанным ключам
      const keys = ['land_function', 'executing_organization', 'owner', 'classification_code', 'classification_code_detail', 'using_mode', 'address', 'origin_executor']
      return _.pick(this.value.properties, keys)
    }
  }
}
</script>

<style scoped>
  * {
    line-height: normal !important;
  }
  .custom-card-title {
    word-break: keep-all;
  }
  .wrapper-items {
    height: calc(100vh - 141px);
    overflow-y: auto;
  }
</style>