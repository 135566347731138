<template>
  <v-card-text class="pa-0 pl-5 object-panel-card_text">
    <v-row
      v-for="(item, index) in objects"
      :key="index"
      class="justify-space-between mt-2 mb-2 custom-row"
    >
      <div
        :style="'background: ' + item.eavEntity.style.color"
        class="object-icon-block"
        :class="blockedObjects[item.eavEntity.id] ? 'blocked' : ''"
      >
        <v-icon
          class="object-icon-block-plus"
          color="white"
        >
          mdi-plus
        </v-icon>
        <v-icon
          color="white"
          @click="createItem(item)"
          :disabled="isDisableCreate(item)"
        >{{ item.eavEntity.icon }}
        </v-icon>
        <div class="object-icon-block-name">{{ item.eavEntity.entityName }}</div>
      </div>
      <div class="justify-space-around custom-icon-block">
        <v-icon
          class="custom-icon"
          @click="hideItem(item, showObjects[item.eavEntity.id])"
          :disabled="isDisableInteraction"
        >{{ showObjects[item.eavEntity.id] ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
        <v-icon
          class="custom-icon"
          @click="showFilters(item)"
          :disabled="isDisableInteraction || !showObjects[item.eavEntity.id]"
        >mdi-tune
        </v-icon>
        <v-icon
          v-show="filterObjects[item.eavEntity.id]"
          class="custom-icon"
          @click="resetFilter(item)"
          :disabled="isDisableInteraction || !showObjects[item.eavEntity.id]"
        >mdi-filter-off
        </v-icon>
        <v-icon
          :disabled="isDisableInteraction || !showObjects[item.eavEntity.id]"
          @click="blockLayer(item, blockedObjects[item.eavEntity.id])"
          class="custom-icon"
        >
          {{ blockedObjects[item.eavEntity.id] ? 'mdi-lock-outline' : 'mdi-lock-open-outline' }}
        </v-icon>
      </div>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: 'ObjectPanel',
  props: {
    objects: Array,
    isDisableInteraction: Boolean,
    currentIdCreatingMapObject: {
      default: null
    }
  },
  data () {
    return {}
  },
  watch: {
    objects: {
      handler () {
        this.objects.forEach((item) => {
          !this.showObjects?.hasOwnProperty(item.eavEntity.id) ? (this.showObjects = {
            id: item.eavEntity.id,
            status: true
          }) : ''
          !this.blockedObjects?.hasOwnProperty(item.eavEntity.id) ? (this.blockedObjects = {
            id: item.eavEntity.id,
            status: false
          }) : ''
          !this.filterObjects?.hasOwnProperty(item.eavEntity.id) ? (this.filterObjects = {
            id: item.eavEntity.id,
            status: false
          }) : ''
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    blockedObjects: {
      get () {
        return this.$store.getters['map/blockedObjects']
      },
      set ({ id, status }) {
        this.$store.commit('map/blockedObjects', { id, status })
      }
    },
    showObjects: {
      get () {
        return this.$store.getters['map/showObjects']
      },
      set ({ id, status }) {
        this.$store.commit('map/showObjects', { id, status })
      }
    },
    filterObjects: {
      get () {
        return this.$store.getters['map/filterObjects']
      },
      set ({ id, status }) {
        this.$store.commit('map/changeFilterObjects', { id, status })
      }
    }
  },
  methods: {
    isDisableCreate (item) {
      return this.currentIdCreatingMapObject !== item.id && typeof this.currentIdCreatingMapObject === 'number' ||
        !this.currentIdCreatingMapObject && this.isDisableInteraction ||
        !this?.showObjects[item.eavEntity.id]
    },
    createItem (data) {
      if (!this.blockedObjects[data.eavEntity.id]) {
        this.$emit('create', { item: data, isCreate: true, type: 'object' })
      }
    },
    hideItem (item) {
      if (this.blockedObjects[item.eavEntity.id]) { //если заблокировано
        this.blockLayer(item)
      }
      this.showObjects = { id: item.eavEntity.id, status: !this.showObjects[item.eavEntity.id] }
      this.$emit('hideItem', { item, status: !this.showObjects[item.eavEntity.id] })
    },
    showFilters (item) {
      this.filterObjects = { id: item.eavEntity.id, status: true }
      this.$emit('showFilters', item)
    },
    resetFilter (item) {
      this.filterObjects = { id: item.eavEntity.id, status: false }
      this.$emit('resetFilter', item)
    },
    blockLayer (item) {
      this.blockedObjects = { id: item.eavEntity.id, status: !this.blockedObjects[item.eavEntity.id] }
      this.$emit('blockLayer', { item, status: this.blockedObjects[item.eavEntity.id] })
    }
  }
}
</script>

<style scoped>
  .object-panel-card_text {
    height: 93svh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .custom-row {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 3px 5px;
    align-self: stretch;
    flex-wrap: wrap;
    padding: 4px;
    border-radius: 8px;
    background: #FFF;
  }
  .object-icon-block {
    display: flex;
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    position: relative;
  }
  .object-icon-block-plus {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 1px;
    right: 1px;
  }
  .object-icon-block-name {
    display: flex;
    width: 80%;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 6px;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-icon-block {
    display: flex;
    height: 51px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
  }
  .custom-icon {
    display: flex;
    font-size: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blocked {
    background-color: #acacac !important;
    opacity: 0.5 !important;
  }
</style>